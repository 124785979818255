import React, { useState } from 'react';
import Card from '../../components/common/Card';
import ContentContainer from '../../components/common/ContentContainer';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectHighlight,
  RefinementList,
  Pagination,
  connectStateResults
} from 'react-instantsearch-dom';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import 'instantsearch.css/themes/reset.css';
import './Search.css';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBlog,
  faCalendarWeek,
  faFile,
  faHandsHelping
} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import {
  faNewspaper,
  faQuestionCircle
} from '@fortawesome/free-regular-svg-icons';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const getHitIcon = (typeId: string): React.ReactNode => {
  let icon = null;
  switch (typeId) {
    case 'resourceItem':
      icon = faFile;
      break;
    case 'event':
      icon = faCalendarWeek;
      break;
    case 'blogPost':
      icon = faBlog;
      break;
    case 'newsItem':
      icon = faNewspaper;
      break;
    case 'resource':
      icon = faFile;
      break;
    case 'eventFaq':
      icon = faQuestionCircle;
      break;
    case 'service':
      icon = faHandsHelping;
      break;
    case 'page':
      icon = faFile;
      break;
    default:
      '';
  }
  return icon;
};

const getItemTitle = (typeId: string): string => {
  let text = '';
  switch (typeId) {
    case 'resourceItem':
      text = 'Resources';
      break;
    case 'event':
      text = 'Events';
      break;
    case 'blogPost':
      text = 'Blog Posts';
      break;
    case 'newsItem':
      text = 'News';
      break;
    case 'resource':
      text = 'Resource Pages';
      break;
    case 'eventFaq':
      text = 'Event FAQ';
      break;
    case 'service':
      text = 'Services';
      break;
    case 'page':
      text = 'Pages';
      break;
    default:
      '';
  }
  return text;
};

const getTypeTitle = (typeId: string): string => {
  let text = '';
  switch (typeId) {
    case 'resourceItem':
      text = 'Resource';
      break;
    case 'event':
      text = 'Event';
      break;
    case 'blogPost':
      text = 'Blog Post';
      break;
    case 'newsItem':
      text = 'News';
      break;
    case 'resource':
      text = 'Resource Page';
      break;
    case 'eventFaq':
      text = 'Event FAQ';
      break;
    case 'service':
      text = 'Service';
      break;
    case 'page':
      text = 'Page';
      break;
    default:
      '';
  }
  return text;
};

const getSlugPath = (typeId: string): string => {
  let text = '';
  switch (typeId) {
    case 'resourceItem':
      text = 'Resource';
      break;
    case 'event':
      text = 'events';
      break;
    case 'blogPost':
      text = 'blog';
      break;
    case 'newsItem':
      text = 'news';
      break;
    case 'resource':
      text = 'resources';
      break;
    default:
      '';
  }
  return text;
};

interface SearchItemLinkProps {
  hit: any;
  children: React.ReactNode;
}

const SearchItemLink: React.FC<SearchItemLinkProps> = props => {
  if (props.hit.type === 'resourceItem') {
    return (
      <a href={props.hit.downloadLink} target="_blank">
        {props.children}
      </a>
    );
  } else if (props.hit.type === 'eventFaq') {
    return <Link to={`/events/faq`}>{props.children}</Link>;
  } else if (props.hit.type === 'service') {
    return <Link to={`/services`}>{props.children}</Link>;
  } else {
    const slugPath = getSlugPath(props.hit.type);
    const path = slugPath
      ? `/${slugPath}/${props.hit.slug}`
      : `/${props.hit.slug}`;
    return <Link to={path}>{props.children}</Link>;
  }
};

const searchClient = algoliasearch(
  '1XGYWDKDHM',
  '1ea7055d53a4200b98313f0b53219fa3'
);

interface TypePillProps {
  type: string;
}
const TypePill: React.FC<TypePillProps> = props => {
  const classNames = classnames({
    'rounded-full px-3 py-1 text-xs sans-serif': true,
    'bg-blue-200 text-primary': props.type === 'event',
    'bg-primary text-blue-100': props.type === 'resource',
    'bg-gray-200 text-gray-800': props.type === 'newsItem',
    'bg-gray-500 text-gray-100':
      props.type === 'blogPost' ||
      props.type === 'service' ||
      props.type === 'eventFaq' ||
      props.type === 'page'
  });
  return (
    <p className={classNames}>
      <FontAwesomeIcon icon={getHitIcon(props.type)} className="mr-2 text-sm" />
      {getTypeTitle(props.type)}
    </p>
  );
};

const Highlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit
  });

  return (
    <div>
      <SearchItemLink hit={hit}>
        <span className="text-xl hover:text-primary hover:underline">
          {parsedHit.map((part: any, index: any) =>
            part.isHighlighted ? (
              <mark key={index}>{part.value}</mark>
            ) : (
              <span key={index}>{part.value}</span>
            )
          )}
        </span>
      </SearchItemLink>
    </div>
  );
};

const CustomHighlight = connectHighlight(Highlight);

const Hit: React.FC = ({ hit }: any) => (
  <div className="flex flex-col">
    <div className="mb-2">
      <CustomHighlight
        attribute={
          hit.type === 'eventFaq'
            ? 'question'
            : hit.type === 'service'
            ? 'name'
            : 'title'
        }
        hit={hit}
      />
    </div>
    <div className="self-start">
      <TypePill type={hit.type} />
    </div>
  </div>
);

const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div>No results found</div>
    )
);

interface SearchProps {}
const Search: React.FC<SearchProps> = props => {
  const location = useLocation();
  const params = parse(location.search);
  const [currentSearchTerm, setCurrentSearchTerm] = useState(params.searchTerm);
  return (
    <InstantSearch searchClient={searchClient} indexName="sitesearch">
      <Layout>
        <SEO title="Search" />
        <ContentContainer>
          <Card>
            <div className="mb-4">
              <h1 className="font-semibold text-3xl text-gray-700 mb-2">
                Search Advancing Literacy
              </h1>
              <SearchBox
                defaultRefinement={currentSearchTerm}
                onReset={() => {
                  if (typeof window !== 'undefined') {
                    window.history.pushState(
                      {},
                      document.title,
                      '/' + 'search'
                    );
                  }
                  location.search = '';
                }}
                translations={{
                  placeholder: 'Enter your search term here'
                }}
              />
              <p className="mt-4">
                Use the search bar to find content across the Advancing Literacy
                website.
              </p>
              <p>
                To search for individual resources available for download, visit
                the{' '}
                <Link to="/resource-center" className="text-primary underline">
                  Resource Center
                </Link>
                .
              </p>
            </div>
            <div className="flex mt-10">
              <div className="w-1/5 mt-4">
                <p className="text-lg text-gray-800 mb-4 sans-serif font-semibold">
                  Refine Search
                </p>
                <RefinementList
                  attribute="type"
                  transformItems={(items: any) => {
                    return items.map((item: any) => ({
                      ...item,
                      label: getItemTitle(item.label)
                    }));
                  }}
                />
              </div>
              <div className="w-4/5">
                <Results>
                  <Hits hitComponent={Hit} />
                </Results>
              </div>
            </div>
            <div className="flex justify-center mt-10">
              <Pagination />
            </div>
          </Card>
        </ContentContainer>
      </Layout>
    </InstantSearch>
  );
  return <></>;
};

export default Search;
